@import '../font/iconfont.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  font-weight: 400;
}

:root {
  --default-color: #4fb94a;
  --default-active-color: #4fb94a;
  --default-border-color: #d9d9d9;
  --default-font-color: #555555;
}