@font-face {
  font-family: "iconfont";
  /* Project id 3946369 */
  src: url('iconfont.woff2?t=1689731457002') format('woff2'),
       url('iconfont.woff?t=1689731457002') format('woff'),
       url('iconfont.ttf?t=1689731457002') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 0.21333333rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pdf:before {
  content: "\e740";
}

.icon-letter:before {
  content: "\e629";
}

.icon-laba:before {
  content: "\e600";
}

.icon-duigou_kuai:before {
  content: "\ed1d";
}

.icon-xiangyoujiantou:before {
  content: "\e65f";
}

.icon-jiantou_xiangyou:before {
  content: "\eb08";
}

.icon-jiantou_xiangzuo:before {
  content: "\eb09";
}

.icon-caidan:before {
  content: "\e65d";
}

.icon-gouwuchetianjia:before {
  content: "\e640";
}

.icon-xiaoxi:before {
  content: "\e641";
}

.icon-chaxun:before {
  content: "\e60b";
}

.icon-yingwen:before {
  content: "\e6c3";
}

.icon-zhongwen:before {
  content: "\e6c8";
}

.icon-jurassic_user:before {
  content: "\e6a4";
}