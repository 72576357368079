.title {
  font-size: x-large;
  text-align: center;
  display: block;
  margin-top: 10PX;
}

.seperate {
  margin-top: 10PX;
  border-top: 2PX solid #8daac7;
}

.content {
  margin-top: 5PX;
  margin-left: 10PX;
  margin-right: 10PX;
}

.header-left {
  float: left;
  margin-left: 5PX;
}

.header-right {
  float: right;
  display: flex;
  margin-left: 5PX;
}

.mytable-search {
  margin-top: 10PX;
  display: flex;
  float: left;
}

.tableSearch {
  width: 200PX;
}

.actionIcon {
  cursor: pointer;
}

.row {
  display: block;
  display: flex;
  flex-direction: row;
  margin-top: .5PX;
  margin-bottom: .5PX;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 5PX;
}

.box-label {
  width: 150PX;
  text-align: right;
  margin-right: 3PX;
}

.box-input {
  width: 300PX;
}

.box-input-long {
  width: 750PX;
}

.richtext-row {
  display: block;
  display: flex;
  flex-direction: row;
  margin-top: 5PX;
  margin-bottom: 5PX;
  height: 300PX;
}

.richtext {
  width: 750PX;
}

.imgStyle {
  height: 500PX;
  width: 800PX;
  display: grid;
  gap: 0.13333333rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.ant-tree-node-selected {
  background-color: #e3fc56 !important;
}