@font-face {
  font-family: poppins-regular;
  src: url(../fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: poppins-semibold;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: poppins-regular;
  color: #333;
  font-size: 0.17333333rem;
  margin: 0;
}

input,
textarea,
select,
button {
  font-family: poppins-regular;
  color: #333;
  font-size: 0.17333333rem;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a:hover {
  text-decoration: none;
}

:focus {
  outline: none;
}

.wrapper {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.inner {
  background: #fff;
  width: 50%;
  margin: auto;
  display: flex;
}

.image-holder {
  width: 50%;
}

.inner .form {
  padding: 0.26666667rem;
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.inner .form .form-content {
  width: 80%;
}

.inner h3 {
  text-transform: uppercase;
  font-size: 0.24rem;
  font-family: poppins-semibold;
  text-align: center;
  margin-bottom: 0.37333333rem;
}

.form-group {
  display: flex;
}

.form-group input {
  width: 1.33333333rem;
}

.form-group input:first-child {
  margin-right: 0.33333333rem;
}

.form-wrapper {
  position: relative;
  margin: 0.2rem 0;
}

.form-wrapper i {
  position: absolute;
  bottom: 0.12rem;
  right: 0;
}

.form-control {
  border: 0.01333333rem solid #333;
  border-top: none;
  border-right: none;
  border-left: none;
  display: block;
  width: 100%;
  height: 0.4rem;
  padding: 0;
  margin-bottom: 0.33333333rem;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.17333333rem;
  color: #333;
  font-family: poppins-regular;
}

.form-control::-moz-placeholder {
  font-size: 0.17333333rem;
  color: #333;
  font-family: poppins-regular;
}

.form-control:-ms-input-placeholder {
  font-size: 0.17333333rem;
  color: #333;
  font-family: poppins-regular;
}

.form-control:-moz-placeholder {
  font-size: 0.17333333rem;
  color: #333;
  font-family: poppins-regular;
}

@media (max-width: 1199px) {
  .wrapper {
    background-position: right center;
  }
}

@media (max-width: 991px) {
  .inner form {
    padding-top: 0.13333333rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}

@media (max-width: 767px) {
  .inner {
    display: block;
  }

  .inner .image-holder {
    width: 6.66666667rem;
  }

  .inner form {
    width: 100%;
    padding: 0.53333333rem 0 0.4rem;
  }

  button {
    margin-top: 0.8rem;
  }
}

.login-title-content {
  position: absolute;
  z-index: 9;
  top: 0.26666667rem;
  left: 0.26666667rem;
}